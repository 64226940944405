import React, { FC } from 'react';
import { OutlinedInputProps, TextField } from "@material-ui/core";
import styley from './index.module.scss';

export interface IInputProps {
    className?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLDivElement> | undefined) => void;
    placeholder?: string;
    required?: true;
    fullWidth?: true;
    name?: string;
    type?: string;
    autoComplete?: string
    label?: string;
    step?: number;
    style?: React.CSSProperties;
    value?: string | number;
    onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    multiline?: boolean;
    rows?: number | string;
    rowsMax?: number | string;
    InputProps?: Partial<OutlinedInputProps> 
    defaultValue?: any;
    disabled?: boolean;
    ref?: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined;
    inputRef? : React.Ref<any> | undefined;
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
    onInput?: React.FormEventHandler<HTMLDivElement> | undefined;
};

const InputField: FC<IInputProps> = (props) => {
    const { className, onChange, placeholder, required, fullWidth, name, type, autoComplete, step, label, 
            value, style, onFocus, onBlur, multiline, rows, rowsMax, InputProps, defaultValue, disabled, ref,
            inputRef, onKeyDown, onClick, onInput } = props;
    return(
        <TextField variant="outlined" margin="normal" required={required} fullWidth={fullWidth} style={style}
        label={label} name={name} placeholder={placeholder} className={`${styley.inputField} ${className}`} type={type} inputProps={{ step: step }}
        autoComplete={autoComplete} onChange={onChange} value={value || ''} onFocus = {onFocus} onBlur={onBlur} multiline={multiline}
        rows={rows} rowsMax={rowsMax} InputProps={InputProps} defaultValue={defaultValue} disabled={ disabled }
        ref={ ref } inputRef={ inputRef } onKeyDown={onKeyDown} onClick={onClick} onInput={ onInput }
        />
    )
}

export default InputField;