import React,{FC} from 'react'
import { Copyright } from '../../utils/copyright'
import styleh from './footer.module.scss'

const Footer : FC = ( ) => {

    return(
        <div className={styleh.footer_main}>
            <Copyright />
        </div>
    )
}

export default Footer;
