import React, { FC, useState } from 'react'
import { ButtonRequest } from '../../utils/button'
import Input from '../../utils/input'
import styleh from './index.module.scss'
import searchy from '../../extras/svg/search.svg'

interface IProps {
    getInsuranceRecord :( data : object )=> void;
    searchStatus : string;
    status : boolean;
    clearSearch:()=> void;
    setRegNumber: React.Dispatch<React.SetStateAction<string>>;
}

type eType = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement >;

const SearchComponent : FC<IProps> = ( props ) => {

    const [ formValue, setFormValue ] = useState({ regNumber: '' });
    const { getInsuranceRecord, clearSearch, setRegNumber } = props;
    const [ errorText, setErrorText ] = useState('');

    const onHandleChange = ( e : eType ) => {
        const { name, value } = e.target
        setFormValue( p => ( { ...p, [ name ] : thousandSep( value ) } ) )
    };

    const onHandleSubmit = () => {
        clearSearch();
        if(formValue!?.regNumber!?.length >= 5 && formValue!?.regNumber!?.length <= 10){
            setRegNumber(formValue!?.regNumber)
            getInsuranceRecord({regNumber: formValue!?.regNumber!?.replaceAll('-', '')})
        }
        else
            setErrorText('Please enter a valid registration number');
    }

    const thousandSep = ( value : string ) => {
        return value.toString().match(/[a-zA-Z0-9]{3}(?=[a-zA-Z0-9]{2,3})|[a-zA-Z0-9]+/g)!?.join("-");
    }

    return(
        <form className={styleh.formstyle} onSubmit={(e)=>{e.preventDefault();onHandleSubmit();}} >
            
            {/* <div className={styleh.search_status}>
                <p className={ status ? styleh.success : styleh.failure }>
                    {searchStatus!?.length > 0 ? (searchStatus === 'Error' ? 'Invalid Insurance' : 'Success') : ''}</p>
            </div> */}
            
            <div className={styleh.input_container}>
                <Input onChange={onHandleChange} value={ Boolean(formValue!?.regNumber) ? formValue!?.regNumber.toUpperCase() : "" } name="regNumber" 
                    placeholder="Enter Vehicle Registration Number" className={styleh.input_style}
                    label="" fullWidth required />
                <p className={styleh.error_text}>{errorText}</p>

                <div className={styleh.searchicon}>
                    <img src={searchy} alt="search icon" />
                </div>
            </div>
            
            <div className={styleh.button_container}>
                <ButtonRequest isLoad={false} isSuccess={false} onClick={onHandleSubmit} title="Check" style={{width: '100%', padding:'30px 0 ', fontSize: '1.5em', fontFamily: 'Poppins'}} />
            </div>

        </form>
    )
}

export default SearchComponent;