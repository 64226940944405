import React from 'react';
import { useState } from 'react';
import Modal from '../utils/modal';

// type UseModalProps = [
//     (name: string, value: boolean) => void,
//     JSX.Element[]
// ] 

type GenericObject = { [ key : string ]: boolean}

type UseModalArgProps = [
    (name: string, value: boolean) => void,
    JSX.Element[]
]

export const useModalWithArg =  <T extends GenericObject> ( 
        Component: Array<JSX.Element>, 
        initialValues: T,
        style?:Array<{[key: string]: any}>
    ): UseModalArgProps  => {

    const [ modal, setModal ] = useState( initialValues );

    const setValueFunc = ( name : string, value : boolean ) => setModal( e => ( { ...e, [ name ] : value } ) )
    
    const ModalComponent = 
        Component.map(( FCComponent, index ) => {
            return(
                <Modal show={ Object.values(modal)[index] } style={style!== undefined ? style![index] : {}}
                    handleClose={ ( )=> setModal( e => ( { ...e, [ Object.keys( modal )[ index ] ] : false } ) ) } key={index} >
                    {FCComponent}
                </Modal>
            )
    } )                
    return [ setValueFunc, ModalComponent ];
}