// export function checkIfNull<R> ( item: R ) {
//     if(item === null || item === undefined || (typeof item === 'string' && item.length === 0)) {
//         return false;
//     }else {
//         return true;
//     }
// }; 
export const checkIfNull = <R,>( item: R ): boolean => {
    if(item === null || item === undefined || (typeof item === 'string' && item.length === 0)) {
        return false;
    }else {
        return true;
    }
}; 

export const checkIfUrl = <R,>( item: R ): boolean => {
    let url: URL;

    try {
        url = new URL(typeof item === 'string' ? item : '')
    } catch(_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';

}

export const dateCleaner = ( item: string )  => {
    if(item!?.length > 0){
        return new Date(item).toDateString()
    }else{
        return ''
    }
};

export const timeCleaner = ( item: string )  => {
    return new Date(item).toLocaleTimeString()
};

export const separator = ( name : string ) => {
    let arr = name!?.split('').map( item => {
        if( item === item!?.toUpperCase() ) return ' ' + item;
        else return item;
    } ).toString().replace(/,/g, '');
    return arr!?.charAt(0).toUpperCase() + arr!?.slice(1).toLowerCase();
};

export const checkIfVid =  <R,>( item: R ): boolean => {
    if (typeof item === 'string' && item.length > 0) {
        if( item.substring(item.lastIndexOf('.')).includes('mp4') ) {
            return true
        }else{
            return false
        }
    }return false
};

export const handleAmount = ( amount : number ) => {
    if(amount){
        var am = amount.toString() ;
        const toNumber = parseFloat(am.replace(/\D/g, ''));
        const toLocale = "₦" +toNumber.toLocaleString( );
        return toLocale;
    } else {return '₦0'} 
};

// export const ImageLoader = (props: ImageLoaderProps) => {
//     const { src = loader, quality = 100, width = 15  } = props;
//     return src
// }
