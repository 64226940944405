import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {Route, Switch, BrowserRouter as Router} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Landing from 'components/pages';
import Success from 'components/pages/success';
import Page404 from 'utils/404';
import loader from 'extras/loader/loader.svg';
import './index.scss';

const Routes = () => (
  <Router>


    <Suspense fallback={ <div className="loader-container"><img src={ loader } alt="loader icon" /></div> } >  

      <Switch>
        <Route exact path='/' component={Landing} />
        <Route exact path='/success/:chasisNumber?/:policyNo?/:certificationNo?/:invoiceNumber?' component={Success} />
        <Route component={Page404} /> 
      </Switch>

    </Suspense>

  </Router>
);

ReactDOM.render(
  <React.StrictMode>
      <Routes />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
