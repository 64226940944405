import React from 'react'
import { Button } from "@material-ui/core";
import loader from 'extras/loader/loaderwhite.svg'
import styley from './index.module.scss'

interface IProps {
    isLoad : boolean;
    title : string | JSX.Element;
    onClick : React.MouseEventHandler<HTMLButtonElement> | undefined;
    isSuccess : boolean;
    className?: string;
    style?: React.CSSProperties;
}

export const ButtonRequest = ( props : IProps ) => {
    
    const { isLoad, title, onClick, isSuccess, className, style } = props

    return(
        <Button variant="contained" color="primary" className={`${styley.btn_request} ${className}`} onClick={onClick} style={style} >
            { isLoad ? <img src={loader} alt="loading" /> : title }
            { isSuccess ? (<i className={`fas fa-check ${styley.ir}`} /> ) : "" }
        </Button> 
    )
}