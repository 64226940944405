import React from 'react';
import styles from 'styles/successpage.module.scss';
import { Footer } from 'components/navigations';
import success from 'extras/success.svg';
import { ButtonRequest } from 'utils/button';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import styley from 'components/result/table.module.scss';

function SuccessPage() {
  
  const { search } = useLocation()
  const successParams = queryString.parse(search);
  const { chasisNumber = '', policyNo = '', certificationNo = '', invoiceNumber = '' } = successParams;

  return(
    <div className={styles.success_page}>
        <div className={styles.container}>
			<h3>Your insurance purchase was successful!</h3>
            <img src={success} alt="valid insurance" />

			<div style={{overflow:"auto"}}>
				<table className={styley.requests__table}>
					<thead>
						<tr>
							<th>Chasis Number</th> 
							<th>Policy Number</th> 
							<th>Certification Number</th> 
							<th>Invoice Number</th> 
						</tr>
					</thead>
					<tbody >
						<tr>				
							<td>
								<p style={{overflowWrap: "break-word"}} className={styley.mt}>{chasisNumber}</p>
							</td>
							<td>
								<p style={{overflowWrap: "break-word"}} className={styley.mt}>{policyNo}</p>
							</td>
							<td>
								<p style={{overflowWrap: "break-word"}} className={styley.mt}>{certificationNo}</p>
							</td>
							<td>
								<p style={{overflowWrap: "break-word"}} className={styley.mt}>{invoiceNumber}</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

            <ButtonRequest isLoad={false} isSuccess={false} onClick={() => void(0)} title="Back to main page" style={{width: '300px', padding:'20px 0 ', fontSize: '1.2em', fontFamily: 'Poppins', marginTop: '20px'}} />
        </div>
		<div className={styles.footer}>
        	<Footer />
		</div>
    </div>
  )
}
  
export default SuccessPage;