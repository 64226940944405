import React, { useState, useEffect } from 'react';
import SearchComponent from 'components/search'
import SearchResult from 'components/result'
import styleh from 'styles/homepage.module.scss'
import axios from 'axios'
import loader from 'extras/loader/loader.svg'
import { IInsuranceCheck } from 'interfaces/IInsurance'
import { Footer } from 'components/navigations';
import { useModalWithArg } from 'hooks/useModal';
import { etraffica_baseurl } from 'utils/constants';

function HomePage() {

  const [ searchStatus, setSearchStatus ] = useState('')
  const [ loading, setIsLoading ] = useState(false)
  const [ response, setResponse ] = useState( {} as IInsuranceCheck)
  const [ message, setMessage ] = useState( '' )
  const [ status, setStatus ] = useState(false);
  const [ isSuccessful, setIsSuccessful ] = useState(false);
  const [ regNumber, setRegNumber ] = useState('');

  const getInsuranceRecord = ( data : object ) => {
    setIsLoading(true)
    axios({url : `${etraffica_baseurl}/sotmanager/insurance/check`, method : 'post', data, timeout: 1000*30, validateStatus : ()=> true })
    .then(res => {
      setResponse( res.data.data )
      setSearchStatus( res.data!?.message )
      setIsLoading(false)
      setMessage(res.data!?.message)
      setStatus( res.data!?.isSuccessful )
      setIsSuccessful(true)
    })
    .catch((err) => {
      setSearchStatus( '' )
      setIsLoading(false)
      setStatus(false)
      setIsSuccessful(true)
    })
  }

  const clearSearch= () => {
    setSearchStatus('')
    setStatus( false )
    setResponse( {} as IInsuranceCheck )
    setMessage('')
  }

  const [ setValueFunc, ModalComponent ] = useModalWithArg( [<SearchResult result={response} message={message} isSuccessful={status} regNumber={regNumber} /> ],{ search: false }, [{}]);

  useEffect(() => {
    if(isSuccessful){
      setValueFunc('search', true)
      setIsSuccessful(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessful])

  return(
    <div className={styleh.index_page}>
      <div className={styleh.container}>

        <div className={styleh.header}>
          <h2>Welcome</h2>
        </div>

        <div className={styleh.description}>
          <p>Validate your <b>Vehicle Insurance</b></p>
        </div>
        
        <SearchComponent getInsuranceRecord={getInsuranceRecord} searchStatus={searchStatus} status={status} clearSearch={clearSearch} setRegNumber={setRegNumber} />

        <div className={styleh.response_container}>
          {loading &&
            <div className={styleh.loader_container}>
            <img src={loader} alt="search icon" />
            </div>
          }
        </div>

      </div>

      <div className={styleh.footer}>
        <Footer />
      </div>

      {ModalComponent}
    </div>
  )
}
  
export default HomePage;