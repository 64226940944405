import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import styley from './table.module.scss'
import styleh from './index.module.scss'
import searchy from 'extras/svg/search.svg'
import getInsurance from 'extras/getInsurance.svg'
import insImage from 'extras/images/car_insurance.svg';
import { IInsuranceCheck, ITRefRequest } from 'interfaces/IInsurance'
import { dateCleaner, handleAmount } from 'utils/helpers'
import { ButtonRequest } from 'utils/button'
import { etraffica_baseurl } from 'utils/constants';

interface IProps {
    result : IInsuranceCheck;
    message: string;
    isSuccessful: boolean;
    regNumber: string;
}

const SearchResult : FC<IProps>  = (  props ) => {

    const { result, message = '', isSuccessful = false, regNumber = '' } = props;
    const { expiryDate = '' } = result || {};
    const [ loading, setIsLoading ] = useState(false);
    const [ response, setResponse ] = useState( {} as ITRefRequest);
    const { data } = response || {} as ITRefRequest;
    const { transactionCode = '' } = data || {};

    const getTransactionCode = ( data : object ) => {
        setIsLoading(true)
        axios({url : `${etraffica_baseurl}/etransactionrequest/web/create`, method : 'post', data, timeout: 1000*30, validateStatus : ()=> true })
        .then((res) => {
          setResponse( res.data )
          setIsLoading(false)
        })
        .catch((err) => {
          console.log(err, 'Error')
        })
    }

    const processTrasaction = `https://niip.ng/home/getInsurance?APIKey=fd56683874293e165b7e9fb528993033d35f937e9a2baa4501754418329a52f7c91aefc778740a5682602ed631eb31222e4cb0c0258f69a1716808e6ec11088d&InsuranceCode=MV&Purpose=Private&InvoiceRef=${transactionCode}`;

    const handleClick = () => {
        getTransactionCode({ regNumber: regNumber!?.replaceAll('-', '') })
    };

    useEffect(() => {
        if(transactionCode!?.length > 0){
            window.open(processTrasaction, '_blank')
        }
        return(() => {
            setResponse({} as ITRefRequest)
        })
    }, [transactionCode, processTrasaction])

    return(
        <>
            {
                message!?.length === 0 ?
                    <div className={styleh.result_not_found}>
                        <p>No Results found</p>
                        <img src={searchy} alt="search icon" />
                    </div>
                :
            
                <div>
                    {isSuccessful?
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign:'center' }}>
                            <h3>Your insurance is valid!</h3>
                            
                            <img src={insImage} alt="valid insurance" height='150px' />
                            <div style={{overflow:"auto"}}>
                                <SearchResultTable tableRecords={ result } />
                            </div>
                        </div>
                        :
                        new Date(expiryDate).getTime() < new Date().getTime() && Object.keys(result)!?.length > 0 ?
                            <div className={styleh.buy_insurance}>
                                <h3>Your insurance is expired...</h3>
                                <img src={getInsurance} alt="get insurance" />
                                <p>Renew your insurance today...</p>
                                <div style={{overflow:"auto"}}>
                                    <SearchResultTable tableRecords={ result } isExpired />
                                </div>
                                <ButtonRequest isLoad={false} isSuccess={false} onClick={() => window.open(processTrasaction, '_blank')} title={`Renew Insurance starting at ${handleAmount(5000)} `} style={{backgroundColor: '#FBC02D', color:'#000', width: '100%', padding:'30px 0 ', fontSize: '1em', fontFamily: 'Poppins'}} />
                            </div>
                            :
                            <div className={styleh.buy_insurance}>
                                <h3>Your insurance is invalid...</h3>
                                <img src={getInsurance} alt="get insurance" />
                                <p>Stay secure and get your vehicle insured today...</p>
                                <ButtonRequest isLoad={loading} isSuccess={false} onClick={handleClick} title={`Buy Original Insurance starting at ${handleAmount(5000)} `} style={{backgroundColor: '#FBC02D', color:'#000', width: '100%', padding:'30px 0 ', fontSize: '1em', fontFamily: 'Poppins'}} />
                            </div>
                    }
                </div>
            }
        </>
    )

}

export default SearchResult;



interface ITableProps{
    tableRecords : IInsuranceCheck;
    isExpired?: boolean;
}

const SearchResultTable : FC <ITableProps> = (props) => {

    const { tableRecords, isExpired = false } = props

    const { expiryDate = '', issueDate = '', policyStatus = '' } = tableRecords || {};

    return(
        <div>
            <table className={styley.requests__table}>
                <thead>
                    <tr>
                        <th>Issue Date</th> 
                        <th>Expiry Date</th> 
                        <th>Policy Status</th> 
                    </tr>
                </thead>
                <tbody >
                    <tr>				
                        <td>
                            <p style={{overflowWrap: "break-word"}} className={styley.mt}>{dateCleaner(issueDate)}</p>
                        </td>
                        <td>
                            <p style={{overflowWrap: "break-word"}} className={styley.mt}>{dateCleaner(expiryDate)}</p>
                        </td>
                        <td>
                            <p style={{overflowWrap: "break-word"}} className={styley.mt}>{policyStatus}
                                {!isExpired! ?
                                    <span> <i className='fa fa-check' style={{color: '#64FDE1'}} /> </span>
                                    :
                                    <span> <i className='fa fa-times' style={{color: '#FF5592'}} /> </span>
                                }
                            </p>
                        </td>
                    </tr>
                       
                </tbody>
            </table>
        </div>
    )
}