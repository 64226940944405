import React from 'react';
import styley from './index.module.scss'

interface IProps {
  handleClose: () => void;
  show: boolean;
  children: JSX.Element;
  className?: string;
  style?: React.CSSProperties;
}

const Modal: React.FC<IProps> = props => {
  const { handleClose, show, children, style } = props;
  const showHideModalClassName = show ? `${styley.modal} ${styley.display_block}` : `${styley.modal} ${styley.display_none}`;

  return (
    <div className={styley.modal_component}>
      <div className={showHideModalClassName}>
        <section className={styley.modal_main} style={style!}>{children}
          <h4 onClick={handleClose}>close</h4>
        </section>
      </div>
    </div>
  );
};

export default Modal;

